
import { Component, Vue } from 'vue-property-decorator';
import client from '@/shared/plugins/content';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  layout: 'default',
  metaInfo: {
    title: 'Vendor Terms of Service ',
    // override the parent template and just use the above title only
  },
})
export default class Terms extends Vue {
  accepted = true;

  settings = {
    maxScrollbarLength: 60,
  };

  title = '';

  terms = '';

  scrolledToBottom = this.accepted ?? false;

  accept = this.accepted ?? false;

  mounted() {
    client
      .getEntry('6d56tOFvYMXV9Xosf8ECrH')
      .then((entry: any) => {
        const richText = entry.fields.company;

        return documentToHtmlString(richText);
      })
      .then((renderedHtml: any) => {
        const html = document.getElementById('rich-text-body');
        if (html) html.innerHTML = renderedHtml;
      });
  }

  acceptFunc() {
    this.scrolledToBottom = true;
  }

  closeModal() {
    if (this.accept && this.accepted) {
      this.accepted = false;
      //   this.$router.go(-1);
    }
    if (this.scrolledToBottom) this.$emit('update:popupActive', false);
  }

  scrollUp() {
    this.scrolledToBottom = false;
  }
}
